import React from 'react'
import {
  Layout,
  SEO,
  ContentWrapper,
  Card,
  Map,
  NavLink
} from '../components/elements';
import Phone from '../images/icons/phone.svg';
import Mail from '../images/icons/mail.svg';
import Globe from '../images/icons/globe.svg';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import styled from '@emotion/styled';
import LocationMarker from '../images/icons/location-marker.svg';

const ContactInfo = styled.div`
  ${tw`flex items-center h-12`}
  span, div{
    ${tw`pl-4`}
  }
  a{
    ${tw`underline`}
  }
`

const Description = styled.div`
  img{
    ${tw`max-h-full`}
  }
`

const Details = ({pageContext}) => {
    const {
      nom, 
      email, 
      produits, 
      telephone, 
      presentation, 
      localisation, 
      adresse, 
      npaLocalite, 
      region, 
      secteur, 
      siteInternet 
    } = pageContext.actor;
    const {actorsLocation} = pageContext;
    
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ( node, children ) => {
          /* run gatsby clean in case of missing field */  
          return (<img src={node.data.target.fields.file['fr-CH'].url} height={"100%"} width={"100%"} />)
        }
      }
    }

    return(
      <ContentWrapper>
        <SEO
          title={nom}
          description={`Page de contact du commercant étique : ${nom}`}
          keywords={[nom, produits && produits.join(', '), npaLocalite]}
        />
        <NavLink to={`/${secteur.slug}/${region.slug}`} />
        <NavLink to={`/`} content="Acceuil" right noArrow/>
        <Card first title={nom} >
          <ContactInfo>
            <Phone height={25}/>
            <span><a target="_blank" href={`tel:${telephone}`}>{telephone}</a></span>
          </ContactInfo>
          <ContactInfo>
            <Mail height={25}/>
            <span><a target="_blank" href={`mailto:${email}`}>{email}</a></span>
          </ContactInfo>
          { siteInternet &&
          <ContactInfo>
            <Globe height={25}/>
            <span><a target="_blank" href={`http://${siteInternet}`}>{siteInternet}</a></span>
          </ContactInfo>
          }
          <ContactInfo>
            <LocationMarker height={25} />
            <a target="_blank" href={`https://google.com/maps/search/?api=1&query=${localisation.lat},${localisation.lon}`}>
              <div>{adresse}</div>
              <div>{npaLocalite}</div>
            </a>
          
          </ContactInfo>
          
        </Card>
       {produits && 
        <Card title="Que propose cet acteur">
          <div>
            {produits.join(', ')}
          </div>
        </Card>
       }
       <Card title="Comment cette entreprise soutient le développement durable ?">
         <Description>
          {documentToReactComponents(presentation.json, options)}
         </Description>
       </Card>
        <Map lat={localisation.lat} lon={localisation.lon} actorsLocation={actorsLocation}/> 
      </ContentWrapper>
    )
}

export default Details